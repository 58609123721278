export default {
  name: "TaskRecord",
  data() {
    return {
      list: [],
      searchCol: {
        page: 1,
        size: 10,
      },
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.getList();
    document.title = "收款记录";
  },
  methods: {
    onLoad() {
      this.getList();
    },
    backHome() {
      this.$router.push("/home");
    },
    getList() {
      this.$axios({
        method: "post",
        url: "api/mobile/userChargeLog/page",
        data: this.searchCol,
      }).then((res) => {
        if (res.data.code === 200) {
          this.list = res.data.data;
          console.log(this.list);
          this.loading = true;
          this.finished = true;
        }
      });
    },
  },
};
